body {
  --purple: #5d64b4;
  --purple-hover: #666bbb;
  --pink: #fd887a;
  --green: #84cf72;

  --twitter: #009df6;
  --linkedin: #0074b8;

  --text-color: #222222;

  --grey: #fafafa;
}

.purple-hover:hover {
  background-color: var(--purple-hover) !important;
}
