@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
html, body {
  margin: 0;
  padding: 0;
}

/* Box sizing on everything */
* {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}


a {
  text-decoration: none;
  color: #222222;
}

p {
  overflow-wrap: break-word;
}

.border-hover-green:hover {
  border-color: var(--green) !important;
}

.twitter-icon:hover {
  color: var(--twitter) !important;
}
.linkedin-icon:hover {
  color: var(--linkedin) !important;
}

h1 {
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  color: #222;
  text-transform: uppercase;
  font-size: 3em;
  letter-spacing: 0.02rem;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  margin: 0;
  margin-top: 0px;
}

h2 {
  font-size: 1.4rem;
  font-weight: 700;
  font-family: var(--theme-font);
  text-transform: uppercase;
  margin: 0;
}

h3 {
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--theme-font);
  text-transform: uppercase;
}

p {
  font-size: 13pt;
  line-height: 22px;
  font-family: var(--text-font);
  letter-spacing: 0.01em;
  color: var(--text-color);
}

footer a {
  color: rgba(0,0,0,0.6);
}

footer a:hover {
  color: var(--green);
}

body {
  --purple: #5d64b4;
  --purple-hover: #666bbb;
  --pink: #fd887a;
  --green: #84cf72;

  --twitter: #009df6;
  --linkedin: #0074b8;

  --text-color: #222222;

  --grey: #fafafa;
}

.purple-hover:hover {
  background-color: var(--purple-hover) !important;
}

body {
  --theme-font: "Montserrat", "Helvetica Neue", sans-serif;
  --text-font: "Times", "Times New Roman", serif;
}

body {
  --content-padding: 5% 10%;
  --border-radius: 2px;
}

input {
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.1);
  font-size: .9rem;
  padding: 5px;
  font-weight: 300;
}



