html, body {
  margin: 0;
  padding: 0;
}

/* Box sizing on everything */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}


a {
  text-decoration: none;
  color: #222222;
}

p {
  overflow-wrap: break-word;
}

.border-hover-green:hover {
  border-color: var(--green) !important;
}

.twitter-icon:hover {
  color: var(--twitter) !important;
}
.linkedin-icon:hover {
  color: var(--linkedin) !important;
}

h1 {
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  color: #222;
  text-transform: uppercase;
  font-size: 3em;
  letter-spacing: 0.02rem;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  margin: 0;
  margin-top: 0px;
}

h2 {
  font-size: 1.4rem;
  font-weight: 700;
  font-family: var(--theme-font);
  text-transform: uppercase;
  margin: 0;
}

h3 {
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--theme-font);
  text-transform: uppercase;
}

p {
  font-size: 13pt;
  line-height: 22px;
  font-family: var(--text-font);
  letter-spacing: 0.01em;
  color: var(--text-color);
}

footer a {
  color: rgba(0,0,0,0.6);
}

footer a:hover {
  color: var(--green);
}
